.app-wrapper__children {
  max-width: 800px;
  margin: auto;
  padding: 4rem 1rem;
}

.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

@media (max-width: 800px) {
  .app-wrapper__children {
    max-width: unset;
  }
}
