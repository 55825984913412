.button {
  padding: 1rem;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  color: black;
  display: inline-block;
  font-size: 1rem;
  text-decoration: none;
}

.button--success {
  background-color: #00d084;
}

.button--success:hover {
  background-color: #7bdcb5;
}

.button--warning {
  background-color: orange;
}

.button--warning:hover {
  background-color: #ffb40a;
}

.button__children {
  visibility: visible;
}

.button__children--loading {
  visibility: hidden;
}

.button-loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  height: 100%;
  position: relative;
  margin-top: -1rem;
}

.button-loading--active {
  visibility: visible;
}

.button-loading__dot {
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  margin: 2px;
  background-color: skyblue;
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  filter: brightness(90%);
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
