.dropdown {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
}

.dropdown > select {
  border: 2px solid #e9e9e9;
  padding: 8px;
  border-radius: 4px;
}

.dropdownn > select:focus {
  border-color: skyblue;
}
