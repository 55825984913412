body {
  margin: 0;
  background-color: #0693e3;
  color: white;
  font-family:
    "Rubik",
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  padding: 0;
}

ol {
  padding: 0;
}

p {
  margin: 0.5rem 0;
}
