.home {
  color: white;
}

.home h1 {
  font-size: 4rem;
}

.home__button {
  margin: 10px;
  margin-top: 100px;
}

.home__illustration {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 20%;
  top: 20%;
}

.home__island {
  width: 600px;
}

.home__pin {
  height: 90px;
  position: absolute;
  animation: scale 1s ease-in infinite alternate;
}

@media (max-width: 1000px) {
  .home__illustration {
    position: static;
  }

  .home__button {
    margin: 20px 20px 0 0;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }

  100% {
    transform: scale(0.75);
  }
}
