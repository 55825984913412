.navbar {
  background-color: transparent;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 410;
}

.navbar__profile-img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
