.modal {
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
  width: 200px;
  z-index: 700;
  position: absolute;
  top: 40%;
  left: calc(50% - 100px);
}

.modal__backdrop {
  background-color: darkblue;
  opacity: 0.5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 600;
  align-items: center;
  justify-content: center;
  display: flex;
}
